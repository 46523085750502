@import '~assets/styles/variables';

.label {
  padding: $spacing;
  text-align: center;
  margin-top: 2em;
}

.lookup {
  display: flex;
  align-items: center;

  &__field {
    width: 233px;
  }
}
