@import '~assets/styles/variables';


.title {
    color: $color--negative !important;
}
.main {
    margin-bottom: 0;
}

.list {
    &__header {
        flex-direction: column;
        justify-content: start;
    }
}
