@import '~assets/styles/variables';
@import '~assets/styles/partials/helpers';

.navigation {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
  background: $color--white;
  border-bottom-right-radius: 4px;
  &__container {
    display: flex;
    align-self: center;
    width: 100%;
    padding-left: 0.75rem;
  }
  &__main {
    flex: 0.65;
    margin-top: 1rem;
    display: flex;
    flex-flow: column;
    row-gap: 2rem;
    align-items: flex-start;
    &__items {
      list-style: none;
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }
  &__secondary {
    display: flex;
    flex-flow: column;
    margin-top: 1rem;
    margin-left: -0.5rem;
    background-color: $color--primary--dark;
    flex: 1;
    border-radius: $border-radius--large 0 0 $border-radius--large;
    &__block-label {
      display: none;
      text-transform: uppercase;
      color: $color--gray--light;
      padding-top: 1rem;
      padding-left: 2.5rem;
    }
    &__items {
      max-height: 80vh;
      list-style: none;
      margin-top: 1rem;
      padding-left: 1.5rem;
      overflow: auto;
      scrollbar-color: $color--primary--light $color--primary--dark;
      scrollbar-width: thin;
    }
    &__item {
      padding: 0.5rem 1rem;
      margin-bottom: 0.5rem;
      border-right: 4px solid transparent;
      border-radius: $border-radius--large 0 0 $border-radius--large;
      &__label {
        font-size: 0.9rem;
        text-transform: capitalize;
        color: $color--primary--lightest;
      }
      &:has(&--active) {
        @include transition-in(background-color, 400ms);
        background-color: $color--primary--light;
        border-color: $color--primary--lightest;
      }
      &:not(:has(&--active)):hover {
        @include transition-in(background-color, 400ms);
        background-color: $color--primary;
        outline: none;
      }
    }
  }
  &__block {
    display: flex;
    align-items: flex-start;
    row-gap: 2rem;
    width: 100%;
    height: 100%;
    &__badge {
      width: 100%;
      position: relative;
      padding: 1rem;
      display: flex;
      flex-flow: column;
      align-items: center;
      border-radius: $border-radius--large 0 0 $border-radius--large;
      &--active {
        @include transition-in(background-color, 200ms);
        background-color: $color--primary--dark;
        .navigation__block__badge__icon,
        .navigation__block__badge__label {
          color: $color--primary--lightest;
        }
      }
      &__icon {
        color: $color--primary--light;
      }
      &__label {
        color: $color--primary;
        font-size: 0.75rem;
        text-align: center;
      }
    }
  }
  &__item {
    padding: $spacing;
    margin-bottom: 1em;
    text-transform: capitalize;
    border-right: 4px solid transparent;
    color: $color--primary--dark;
    &:hover {
      background: $color--accent--lightest;
    }
    &--active {
      border-color: $color--accent;
      color: $color--accent;
      background: $color--accent--lightest;
    }
  }
  &--collapsed {
    .navigation {
      &__container {
        padding: 0;
      }
      &__main {
        margin: 1rem auto 0;
      }
      &__secondary {
        display: none;
        box-shadow: $shadow--large;
        position: absolute;
        z-index: $z-index--dropdown--in-modal;
        left: 107px;
        border-radius: $border-radius--large;
        &__block-label {
          display: inline-block;
        }
      }
      &__block__badge {
        height: 55px;
        border-radius: $border-radius--large;
        &__label {
          display: none;
        }
      }
    }
  }
}

.navigation__container:hover {
  .navigation__secondary {
    display: flex;
  }
}

.navigation--collapsed .navigation__container:hover {
  .navigation__block__badge::after {
    width: 0;
    content: '';
    display: inline-block;
    position: absolute;
    background-color: $color--primary--dark;
    z-index: 1000;
    top: 0;
    left: 60px;
    height: 100%;
  }
  .navigation__block__badge--active::after {
    @include transition-in(width, 600ms);
    width: 40px;
  }
}

.global-items {
  max-width: 100px;
  z-index: $z-index--dropdown;
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  &--collapsed {
    align-items: center;
  }
}
